<template>
  <div class="home">

    <div>
        <img class="frank2" src="../assets/renegade_mobile3.webp">
        <div class="stickdown" v-on:click="scrolldown"></div>
        <transition appear name="tran1">
          <p class="frank_text1">地域の</p>
        </transition>
        <transition appear name="tran2">
          <p class="frank_text2">カーライフを</p>
        </transition>
        <transition appear name="tran3">
          <p class="frank_text3">サポートします</p>
        </transition>
    </div>

    <Gcoin/>

    <div class="top_view">
    </div>

    <Menu/>

    <Greet/>

    <TireScroll/>


    <div class="pick_up2">
        <h2 class="pick_up2_h2">
        Charge
        </h2>
        <p class="pick_up2_p">
        交換費用について
        </p>

        <div class="price_class">
          <h2 class="charge_h21_home">当店ご購入のタイヤの脱着</h2>
          <div class="charge_box_home">
            <p class="charge_p2_home">2,200</p>
            <div class="yen_box_home">
              <p class="yen_box_p1_home">(税込)</p>
              <p class="yen_box_p2_home">円</p>
            </div>
            <p class="charge_slash_home">/1台</p>
          </div>
        <div class="home_about2">
          <a href="https://taiyakan-gotemba.com/charge">More</a>
        </div>
        </div>
    </div>

    <div class="pick_up">
        <h2>
        Instagram
        </h2>
        <p>
        作業事例
        </p>
    </div>

    <HelloWorld msg="CAMRY"/>
    <Second msg="HIACE"/>
    <Third msg="GRスープラ"/>
    <RightInsta src_data_props="text1" imgnumber_props="img1"/>

    <p class="p_stickarrow"><router-link class="routerlink_stickarrow" to="/instagram">more</router-link></p>
    <router-link class="routerlink_stickarrow" to="/instagram">
      <div :class="{fadeIn:visible}" v-show="visible">
      <div class="stickarrow">
      </div>
      </div>
    </router-link>

    <WheelAlignment/>

    <div class="pick_up3">
        <h2 class="pick_up3_h2">
        FAQ
        </h2>
        <p class="pick_up3_p">
        よくあるご質問
        </p>
        <img class="img_faq1" src="../assets/faq2.png">
        <div class="faq_class">
          <dl class="faq10">
            <dt>Q</dt><dd>作業は予約制ですか？</dd>
            <dt>A</dt><dd>予約制ではございません。ご来店していただいた順番でご対応しています。
            </dd>
          </dl>
        </div>
        <div class="home_about3">
          <a href="https://taiyakan-gotemba.com/faq">More</a>
        </div>
    </div>

    <router-link class="muji" to="/waiting">
      <Crowded_noreload/>
    </router-link>

    <TireWinter/>

    <div class="home_about">
      <p class="about_p">ACCESS</p>
      <h1>タイヤ館 御殿場</h1>
      <p class="about_p">静岡県御殿場市杉名沢356-4</p>
      <a href="https://goo.gl/maps/eRvNR9KRgGpfYQPp7">Google Maps</a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Second from '@/components/Second.vue'
import Third from '@/components/Third.vue'
import Menu from '@/components/Menu.vue'
import Greet from '@/components/Greet.vue'
import TireScroll from '@/components/TireScroll.vue'
import TireWinter from '@/components/TireWinter.vue'
import Crowded_noreload from '@/components/Crowded_noreload.vue'
import RightInsta from '@/components/RightInsta.vue'
import Gcoin from '@/components/Gcoin.vue'
import WheelAlignment from '@/components/WheelAlignment.vue'


export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    Second,
    Third,
    Menu,
    Greet,
    TireScroll,
    Crowded_noreload,
    RightInsta,
    Gcoin,
    WheelAlignment,
    TireWinter
  },
  data(){
    return{
    ActiveBtn: false,
    show: true,
    visible: false,
    visible1: false,
    windowSize: window.innerWidth,
    }
  },
  methods:{
    trueshow: function() {
    this.show = "ture"
    },
    falseshow: function() {
    this.show = "false"
    },
    handleScroll() {
      if (!this.visible) {
        this.visible = window.scrollY > 3000;
      }else if(window.scrollY <2990){
        this.visible = !this.visible;
      }
    },
    handleScroll1() {
      if (!this.visible1) {
        this.visible1 = window.scrollY > 2000;
      }else if(window.scrollY < 590){
        this.visible1 = !this.visible;
      }
    },
    scrolldown() {
        autoScroll(scrollY);
        function autoScroll(y){
            if (y>670 || scrollY>y+1000) {
                return
            }
            y=y+5; // 垂直方向のスクロール量を減らす
            scrollTo(scrollX,y);
            setTimeout(autoScroll,1,y);
        }
    },
    handleResize: function() {
      this.windowSize = window.innerWidth;
    }
  },
  created() {
  },
  mounted: function() {
    setInterval(this.trueshow, 1000);
    setInterval(this.falseshow, 1500);
    if (this.$browserBackFlg) {
      location.reload();
    }
    else {
    //
    }
    window.addEventListener('resize', this.handleResize)
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("scroll", this.handleScroll1);
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
      window.removeEventListener("scroll", this.handleScroll1);
    }
}
</script>

<style scoped>
.muji{
  color:inherit;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .price_class{
    padding-left: 11rem;
  }
  .routerlink_stickarrow{
    padding-left: 11rem;
  }
  .stickarrow{
    padding-left: 11rem;
    margin-top: -1rem;
  }
  .top_view{
    background-image: url(../assets/renegade.jpg);
  }
  .frank2{
    width: 88%;
  }
  .frank_text1 {
    position: absolute;
    margin: 0.3em;
    padding-left: 0.3em;
    padding-right: 0.3em;
    top: 19.0em;
    font-size: 2.0em;
    text-align:left;
    color: black;
    background-color: white;
    font-weight: bold;
    letter-spacing : 0.15rem;
  }
  .frank_text2 {
    position: absolute;
    margin: 0.3em;
    padding-left: 0.3em;
    padding-right: 0.3em;
    top: 20.6em;
    font-size: 2.0em;
    text-align:left;
    color: black;
    background-color: white;
    font-weight: bold;
    letter-spacing : 0.15rem;
  }
  .frank_text3 {
    position: absolute;
    margin: 0.3em;
    padding-left: 0.3em;
    padding-right: 0.3em;
    top: 22.2em;
    font-size: 2.0em;
    text-align:left;
    color: black;
    background-color: white;
    font-weight: bold;
    letter-spacing : 0.15rem;
  }
}
@media screen and (max-width: 767px) {
  .price_class{
    padding-left: 0rem;
  }
  .top_view{
    background-image: url(../assets/renegade_mobile3.jpg);
  }
  .frank2{
    width: 133%;
    margin-left: -5.5rem;
  }
  .frank_text1 {
    position: absolute;
    margin: 0.3em;
    padding-left: 0.3em;
    padding-right: 0.3em;
    top: 13.0em;
    font-size: 2.0em;
    text-align:left;
    color: black;
    background-color: white;
    font-weight: bold;
    letter-spacing : 0.15rem;
  }
  .frank_text2 {
    position: absolute;
    margin: 0.3em;
    padding-left: 0.3em;
    padding-right: 0.3em;
    top: 14.6em;
    font-size: 2.0em;
    text-align:left;
    color: black;
    background-color: white;
    font-weight: bold;
    letter-spacing : 0.15rem;
  }
  .frank_text3 {
    position: absolute;
    margin: 0.3em;
    padding-left: 0.3em;
    padding-right: 0.3em;
    top: 16.2em;
    font-size: 2.0em;
    text-align:left;
    color: black;
    background-color: white;
    font-weight: bold;
    letter-spacing : 0.15rem;
  }
}


.renegade{
  width: 200%;
  margin-left: -22.5rem;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
.pick_up h2{
  letter-spacing: 0.05em;
}
.pick_up p{
  font-weight: 600;
  font-size: 14px;
  margin-top: -15px;
  padding-bottom: 20px;
  letter-spacing: 0.2em;
}

.pick_up2_h2{
  letter-spacing: 0.05em;
}
.pick_up2_p{
  font-weight: 600;
  font-size: 14px;
  margin-top: -15px;
  padding-bottom: 10px;
  letter-spacing: 0.2em;
}

.pick_up2{
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: -1rem;
}

.pick_up3_h2{
  letter-spacing: 0.05em;
}
.pick_up3_p{
  font-weight: 600;
  font-size: 14px;
  margin-top: -15px;
  padding-bottom: 10px;
  letter-spacing: 0.2em;
}

.pick_up3{
  padding-top: 8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: -2rem;
}

.home_about{
  padding-top: orem;
  padding-bottom: 180px;
  text-align:left;
  margin-left: 30px;
}
.home_about a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #90be70; /*#789691 or #90be70*/
  border-radius: 40px;
  margin-top: 30px;
}
.home_about a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #2b550e;
  border-right: 2px solid #2b550e;
  transform: rotate(45deg);
}
.home_about2{
  padding-top: 70px;
  padding-bottom: 90px;
  text-align:left;
}
.home_about2 a{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #90be70; /*#789691 or #90be70*/
  border-radius: 40px;
  margin-top: 30px;
}
.home_about2 a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #2b550e;
  border-right: 2px solid #2b550e;
  transform: rotate(45deg);
}
.home_about3{
  margin-top: -3rem;
  padding-bottom: 90px;
  text-align:left;
}
.home_about3 a{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #90be70; /*#789691 or #90be70*/
  border-radius: 40px;
  margin-top: 30px;
}
.home_about3 a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #2b550e;
  border-right: 2px solid #2b550e;
  transform: rotate(45deg);
}

.about_p{
  font-size: 12px;
  margin-bottom: 20px;
  font-weight: 600;
}


.pc_frank_text1 {
  position: absolute;
  margin: 0.3em;
  margin-left: -20rem;
  padding-left: 0.3em;
  padding-right: 0.3em;
  top: 20.0em;
  font-size: 2.0em;
  text-align:left;
  color: black;
  background-color: white;
  font-weight: bold;
  letter-spacing : 0.15rem;
}
.pc_frank_text2 {
  position: absolute;
  margin: 0.3em;
  margin-left: -20rem;
  padding-left: 0.3em;
  padding-right: 0.3em;
  top: 21.6em;
  font-size: 2.0em;
  text-align:left;
  color: black;
  background-color: white;
  font-weight: bold;
  letter-spacing : 0.15rem;
}
.pc_frank_text3 {
  position: absolute;
  margin: 0.3em;
  margin-left: -20rem;
  padding-left: 0.3em;
  padding-right: 0.3em;
  top: 23.2em;
  font-size: 2.0em;
  text-align:left;
  color: black;
  background-color: white;
  font-weight: bold;
  letter-spacing : 0.15rem;
}

.stickarrow {
  width: 80px;
  height: 8px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  transform: skew(45deg);
  margin-left: 70%;
}
.p_stickarrow{
  margin-left: 52%;
  margin-bottom: -0.5em;
  margin-top: -4.5rem;
}
.routerlink_stickarrow{
  color: black;
  text-decoration: none;
}


.stickdown {
  display: inline-block;
  vertical-align: middle;
  color: white;
  line-height: 1;
  width: 1em;
  height: 1em;
  border: 0.1em solid currentColor;
  border-left: 0;
  border-bottom: 0;
  box-sizing: border-box;
  transform: translateY(-295%) rotate(135deg);
  position: absolute;
  animation: flash 1.5s linear infinite;
  margin-top: -2rem;
  z-index: 100;
}

.p_stickdown{
  margin-left: 52%;
  margin-bottom: -0.5em;
  position: absolute;
}


@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tran1-enter,
.tran1-leave-to {
  opacity: 0;
}
.tran1-enter-active,
.tran1-leave-active {
  transition: 1s;
}

.tran2-enter,
.tran2-leave-to {
  opacity: 0;
}
.tran2-enter-active,
.tran2-leave-active {
  transition: 1.5s;
}

.tran3-enter,
.tran3-leave-to {
  opacity: 0;
}
.tran3-enter-active,
.tran3-leave-active {
  transition: 2s;
}

.fadeIn {
  animation: fadeIn 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(200px);
    transition-duration:.7s;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition-duration:.7s;
  }
}
.fadeIn1 {
  animation: fadeIn1 1s;
}
@keyframes fadeIn1 {
  0% {
    opacity: 0;
    transform: translateY(200px);
    transition-duration:.7s;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition-duration:.7s;
  }
}


.charge_box_home{
  display:flex;
  margin-bottom: -7rem;
}
.charge_h21_home{
  padding-top: 2rem;
  font-size: 1.5rem;
  font-weight: 700;
  text-align:left;
  margin-bottom: -2.5rem;
  position: relative;
}
.charge_h21_home::before{
    position: absolute;
    bottom: 0;
    content: '';
    width: 7.7rem;
    border-bottom: 3px solid #789691;
}
.charge_p2_home{
  padding-top: 0rem;
  font-size: 3rem;
  font-weight: 700;
}
.charge_p3_home{
  margin-bottom: -2.6rem;
  padding-top: 2rem;
  font-size: 0.8rem;
  text-align:left;
}
.yen_box_p1_home{
  font-size: 0.5rem;
  font-weight: 700;
  padding-left: 0.2rem;
  margin-bottom: -1.8rem;
  padding-top: 3.4rem;
}
.yen_box_p2_home{
  font-size: 1.5rem;
  padding-left: 0.2rem;
  font-weight: 700;
}
.charge_slash_home{
  font-size: 1.5rem;
  font-weight: 700;
  padding-top: 3.0rem;
  padding-left: 0.5rem;
}

.img_faq1{
  width: 40%;
  height: 40%;
  margin-right: -0.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.faq10{
    font-size: 0.9rem;
    font-weight: bold;
    padding-bottom: 4rem;
    line-height: 1.8;
    letter-spacing: 0.03em;
}
.faq10 dd{
    padding-bottom: 0.7rem;
}
.faq_class dt{
  float: left;
  font-size: 1rem;
}

.faq_class dt::after{
  content: "."
}
.faq_class dd{
  text-align:left;
  font-size: 1.05rem;
}
</style>
